/* eslint-disable max-lines */
export enum AlertSeverity {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export enum AllocationStatus {
  Full = 'FULL',
  Partial = 'PARTIAL',
  Unallocated = 'UNALLOCATED',
}

export enum AllocationType {
  ManualPayment = 'MANUAL_PAYMENT',
  ManualRefund = 'MANUAL_REFUND',
  MerchantSettlement = 'MERCHANT_SETTLEMENT',
  StripePayout = 'STRIPE_PAYOUT',
  Suspense = 'SUSPENSE',
  TransferCompass = 'TRANSFER_COMPASS',
  TransferCurrent = 'TRANSFER_CURRENT',
  TransferNCP = 'TRANSFER_NCP',
  Unallocated = 'UNALLOCATED',
}

export enum ApiKeys {
  Private = 'PRIVATE_KEY',
  Public = 'PUBLIC_KEY',
}

export enum ApplicantBlockActions {
  Create = 'create',
  Modify = 'modify',
  Remove = 'remove',
}

export enum ArrearsManagedByKeys {
  Covisian = 'covisian',
  CreditReform = 'creditreform',
  Cribis = 'cribis',
  HeidiPay = 'heidipay',
  InKasso = 'inkasso',
  SoniaLaurenco = 'sonia laurenco',
}

export enum BankAccountReconciliationView {
  BankAccountReconciliations = 'bank_account_reconciliations',
  Suspense = 'suspense',
}

export enum ButtonType {
  Button = 'button',
  Reset = 'reset',
  Submit = 'submit',
}

export enum ButtonVariant {
  Danger = 'danger',
  Light = 'light',
  Link = 'link',
  Primary = 'primary',
  Secondary = 'secondary',
  Sm = 'sm',
  Text = 'text',
  Xs = 'xs',
}

export enum Endpoints {
  AcceptEarlySettlement = 'early-settlement/accept',
  AddContractNote = 'contracts/add-note',
  AdjustContract = 'orders/adjust-contract',
  AdjustSettlement = 'settlements/adjust',
  ArrearsManagement = 'collections/arrears-management',
  AssignmentHistory = 'collections/external-agency-history',
  BlockExternalCollectionsReferral = 'collections/block-auto-referral',
  CancelContract = 'orders/cancel-contract',
  CancelEarlySettlement = 'early-settlement/cancel',
  CancelPaymentLink = 'payment-link/cancel-payment-link',
  ChangeMerchantIban = 'merchants/iban/change',
  ChangePaymentDates = 'contracts/change-dates',
  ChangePaymentDay = 'contracts/change-date',
  ConfirmCompassContract = 'contracts/confirm-compass-contract',
  CreateApplicantBlock = 'applications/create-applicant-block',
  CreateFullEarlySettlement = 'early-settlement/create-full',
  CreateNewOrder = 'orders/new',
  CreatePartialEarlySettlement = 'early-settlement/create-partial',
  CreatePaymentLink = 'payment-link/create-payment-link',
  CustomerAgreement = 'applications/customer-agreement',
  CustomerAgreementSigned = 'applications/signed-customer-agreement',
  DeleteFees = 'contracts/delete-fees',
  DeleteSettlement = 'settlements/delete',
  DeleteUser = 'user/edit-user-delete',
  DownloadAllSettlements = 'settlements/csv-all',
  DownloadBalanceTimelinePdf = 'contracts/balance-timeline',
  DownloadQrPdf = 'contracts/download-qr-pdf',
  DownloadSingleSettlement = 'settlements/csv',
  EditUser = 'user/edit-user-groups',
  Env = 'env',
  FetchAllDocuments = 'documents',
  FetchAllocationDetails = 'bank-account-transactions/transaction',
  FetchApiRequests = 'applications/api-requests',
  FetchApplicantBlockStatus = 'applications/get-blocked-status',
  FetchApplicationActionLogs = 'applications/action-logs',
  FetchApplications = 'applications',
  FetchBankAccountTransactions = 'bank-account-transactions',
  FetchBlockedApplicantDetails = 'applications/get-blocked-applicant-details',
  FetchBlockedApplicants = 'applications/get-blocked-applicants',
  FetchCollectionAgencies = 'contracts/collection-agencies',
  FetchCollections = 'collections',
  FetchCommunicationsEvent = 'contracts/communication-event',
  FetchCommunicationsEvents = 'contracts/communication-events',
  FetchConsumerEvent = 'consumers/event',
  FetchConsumerEvents = 'consumers/events',
  FetchConsumers = 'consumers',
  FetchContractDocument = 'documents/contract-document',
  FetchContractDocuments = 'documents/contract-documents',
  FetchContracts = 'contracts',
  FetchContractsByUuids = 'contracts/by-contract-uuids',
  FetchCustomerEmailTemplatesList = 'consumers/email/templates-list',
  FetchEarlySettlement = 'early-settlement',
  FetchEarlySettlementList = 'early-settlement/list',
  FetchMediobancaSettlementsData = 'mediobancaSettlements/full-details',
  FetchMerchants = 'merchants',
  FetchMerchantsAPIKeys = 'merchants/keys',
  FetchMerchantsAll = 'merchants/all',
  FetchMerchantsIban = 'merchants/iban',
  FetchMonetaContractData = 'contracts/moneta',
  FetchMyOrders = 'orders/my-orders',
  FetchMyOrdersCsv = 'orders/my-orders/csv',
  FetchNextPaymentData = 'contracts/next-payment',
  FetchNotes = 'contracts/notes',
  FetchOrders = 'orders',
  FetchOrdersArrearsData = 'orders/is-arrears',
  FetchOrdersAwaitingDispatch = 'orders/awaiting-dispatch',
  FetchOrdersAwaitingDispatchCsv = 'orders/awaiting-dispatch/csv',
  FetchOrdersCsv = 'orders/csv',
  FetchOrgIndustries = 'org/industries',
  FetchOrgSettings = 'org/settings',
  FetchOverpaidContracts = 'contracts/overpaid',
  FetchPausedContracts = 'contracts/paused',
  FetchPaymentLink = 'payment-link/get-payment-link',
  FetchPaymentsEvents = 'contracts/payment-events',
  FetchPendingAllocations = 'bank-account-transactions/bank-account-pending-allocations',
  FetchPortal = 'portal',
  FetchSettlementSummary = 'settlements/summary',
  FetchSettlements = 'settlements',
  FetchSingleDocument = 'documents/get_document',
  FetchTerminableContracts = 'contracts/terminable',
  FetchTimelineEvents = 'collections/collection-events',
  FetchUnbalancedAllocations = 'bank-account-transactions/unbalanced-allocations',
  FetchUserAddress = 'user/address',
  FetchUserGroupsData = 'user/groups',
  FetchUserSettings = 'user/settings',
  GenerateCertificateOfInterest = 'contracts/certificate-of-interests/generate',
  GetUserByToken = 'user/getUserByToken',
  HandlePendingAllocation = 'bank-account-transactions/allocation/approval-status',
  InitCardChange = 'orders/init-card-change-v2',
  ListCertificateOfInterest = 'contracts/certificate-of-interests/view',
  MakeManualPayment = 'contracts/payment',
  ModifyApplicantBlock = 'applications/modify-applicant-block',
  PauseCollectionsCommunications = 'collections/pause-communications',
  PauseMerchantSettlement = 'merchants/pause-merchant-settlement',
  PausePaymentIndefinitely = 'contracts/pause-payments',
  PausePaymentsTillDate = 'contracts/pause-payments-till-date',
  PostAllocationTransaction = 'bank-account-transactions/allocation',
  PrePayContract = 'contracts/prepayment',
  PrePayOrder = 'orders/prepayment',
  RefundOrder = 'orders/refund',
  ReimburseSettlement = 'settlements/reimburse',
  RemoveApplicantBlock = 'applications/remove-applicant-block',
  ResumePayments = 'contracts/resume-payments',
  ResumePaymentsTillDate = 'contracts/resume-payments-till-date',
  RiskAndWaterfall = 'contracts/risk-and-waterfall',
  SelectEmailTemplate = 'consumers/email/get-template',
  SendCertificateOfInterest = 'contracts/certificate-of-interests/send',
  SendCustomerEmail = 'consumers/send_email',
  TerminateContractsBatch = 'contracts/terminate-batch',
  TrackOrder = 'orders/track',
  TrackOrderOps = 'orders/ops/track',
  TriggerCompassDocumentUpload = 'contracts/trigger-compass-document-upload',
  TriggerSinglePayment = 'contracts/trigger-payment',
  UpdateArrearsManagedBy = 'contracts/arrears-managed-by/update',
  UploadBankAccountTransactions = 'bank-account-transactions/upload',
  UploadManualPaymentsCsv = 'contracts/manual-payment-csv',
  UserToken = 'user/token',
}

export enum Environments {
  Dev = 'development',
  Prod = 'production',
  QA = 'QA',
  Sandbox = 'sandbox',
}

export enum OriginationChannel {
  Ecommerce = 'ecommerce',
  InStore = 'in_store',
}

export enum PageRoutes {
  AccountSettings = '/account_settings',
  AllContracts = '/all_contracts',
  ApiCalls = '/api_calls',
  Applications = '/applications',
  Auth = '/auth',
  AwaitingConfirmation = '/awaiting_confirmation',
  AwaitingDispatch = '/awaiting_dispatch',
  BankAccountReconciliations = '/bank-account-reconciliations',
  BlockedApplicants = '/blocked-applicants',
  CardChangeGuide = '/card-change-guide',
  Collections = '/collections',
  Consumers = '/consumers',
  Contracts = '/contracts',
  CreateNewPlan = '/create_a_new_plan',
  Documents = '/documents',
  Error = '/error',
  InStoreSalesGuide = '/instore-sales-guide',
  Logout = '/logout',
  MerchantGuide = '/merchant_guide',
  MerchantPrivacyPolicy = '/merchant_privacy_policy',
  Merchants = '/merchants',
  MyApplications = '/my-applications',
  MyOrders = '/my-orders',
  Orders = '/orders',
  OrgInfo = '/organisation_information',
  OverpaidContracts = '/overpaid_contracts',
  PausedContracts = '/paused_contracts',
  PendingAllocations = '/pending-allocations',
  Settlements = '/settlements',
  Suspense = '/suspense',
  TerminableContracts = '/terminable_contracts',
  Track = '/track',
  Transactions = '/transactions',
  UnbalancedAllocations = '/unbalanced-allocations',
  Users = '/users',
  Utilities = '/utilities',
}

export enum UserType {
  Agency = 'agency',
  Ops = 'ops',
}

export enum ValidationErrorKeys {
  AddressFirstLineRequired = 'VALIDATION.ADDRESS_FIRST_LINE_REQUIRED',
  AddressHasNotChanged = 'VALIDATION.ADDRESS_HAS_NOT_CHANGED',
  AddressPostCodeRequired = 'VALIDATION.ADDRESS_POST_CODE_REQUIRED',
  AddressStreetNumberRequired = 'VALIDATION.ADDRESS_STREET_NUMBER_REQUIRED',
  AddressTownRequired = 'VALIDATION.ADDRESS_TOWN_REQUIRED',
  AmountCannotExceedContractValue = 'OPS_PORTAL.VALIDATION.AMOUNT_CANNOT_EXCEED_CONTRACT_VALUE',
  AmountCannotExceedOrderBalance = 'VALIDATION.AMOUNT_CANNOT_EXCEED_ORDER_BALANCE',
  AmountCannotExceedOrderValue = 'VALIDATION.AMOUNT_CANNOT_EXCEED_ORDER_VALUE',
  AmountCannotExceedRemainingBalance = 'OPS_PORTAL.VALIDATION.AMOUNT_CANNOT_EXCEED_REMAINING BALANCE',
  AmountCannotExceedSettlementValue = 'OPS_PORTAL.VALIDATION.AMOUNT_CANNOT_EXCEED_SETTLEMENT_VALUE',
  AmountMustBeNumber = 'VALIDATION.AMOUNT_MUST_BE_A_NUMBER',
  DateHasNotChanged = 'OPS_PORTAL.VALIDATION.DATE_HAS_NOT_CHANGED',
  InvalidAmount = 'OPS_PORTAL.VALIDATION.INVALID_AMOUNT',
  InvalidEmailFormat = 'VALIDATION.INVALID_EMAIL_FORMAT',
  InvalidIban = 'OPS_PORTAL.VALIDATION.INVALID_IBAN',
  InvalidLogin = 'VALIDATION.INVALID_LOGIN',
  InvalidPaymentDate = 'OPS_PORTAL.VALIDATION.INVALID_PAYMENT_DATE',
  Max10Characters = 'VALIDATION.MUST_BE_10_CHARACTERS_OR_FEWER',
  MaxCharsX = 'VALIDATION.MAX_CHARS',
  MaxNumX = 'OPS_PORTAL.VALIDATION.MAX_NUM',
  Min1FeeSelection = 'OPS_PORTAL.VALIDATION.MIN_1_DELETE_FEE',
  MinCharsX = 'VALIDATION.MIN_CHARS',
  MinLowerCase = 'VALIDATION.MIN_LOWER_CASE',
  MinNum0 = 'VALIDATION.AMOUNT_MUST_BE_GREATER_THAN_OR_EQUAL_TO_0',
  MinNum1 = 'VALIDATION.AMOUNT_MUST_BE_GREATER_THAN_0',
  MinNumX = 'OPS_PORTAL.VALIDATION.MIN_NUM',
  MinNumbers = 'VALIDATION.MIN_NUMBERS',
  MinSymbols = 'VALIDATION.MIN_SYMBOLS',
  MinUpperCase = 'VALIDATION.MIN_UPPER_CASE',
  PasswordsMustMatch = 'VALIDATION.PASSWORDS_MUST_MATCH',
  PaymentPlanIdMustMatch = 'VALIDATION.PAYMENT_PLAN_ID_MUST_MATCH_PAYMENTPLANID',
  PhoneNumberHasNotChanged = 'VALIDATION.PHONE_NUMBER_HAS_NOT_CHANGED',
  Required = 'VALIDATION.REQUIRED',
  ValidUuids = 'OPS_PORTAL.VALIDATION.MUST_CONTAIN_VALID_UUIDS',
  ValueHasNotChanged = 'OPS_PORTAL.VALIDATION.VALUE_HAS_NOT_CHANGED',
}
